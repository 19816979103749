<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case02/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          照明のプロが不在で課題が把握されず
          <br />最適な照明や光環境が構築できない
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          検査工程の照明を設計する際に、照明や光に関するプロフェッショナルが不在である場合がほとんどです。そのため、ライン照明が適切に構築できていなかったり、適切な機材が選定されておらず、作業性の低下につながっているケースは珍しくありません。また、光環境に関するデータやログが残されていないために、課題が把握されていなかったり、どのように改善を行うべきか目標値を設定できないケースも多くみられます。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
              >
                新たに検査現場を構築したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
              >
                現状の課題を確認したい
              </router-link>
            </li>
            <li>
              <router-link class="category" :to="{ name: 'Case', query: { category: '塗装完成車工程' }}">
                塗装完成車工程
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=現場調査">
                現場調査
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=施策策定">
                施策策定
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                to="/case?category=コンサルティング"
              >
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=納品後サポート">
                納品後サポート
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>塗装検査のライン照明</li>
          <li>シリンダーヘッドやコンロッドなどの検査台</li>
          <li>エンジンブロックなどで使用される対象ワークの可動検査台</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題1
            </p>
            <p class="txt">
              適切な光環境が実現できない
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策1</span>
            <p class="txt">
              コンサルティングを実施し課題解決
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case02/image01.jpg" alt="" />
            </p>
            <p></p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            現場のデータ測定やヒアリングを実施し、どこに問題があるのかを探り、顕在化していない課題を認識します。
            <br>適切な照明器具や設置方法を検討して、改善を行いながら適切なゼブラ照明などの光環境を構築し、不良の発見率向上により生産効率向上に結びつけます。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-2-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題2
            </p>
            <p class="txt">
              ログがないため適切な改善策がとれない
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <p class="ttl">
              施策2
            </p>
            <p class="txt">
              データ測定を行い光環境を見える化
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case02/image02.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            お客様の検査ラインに実際に車体を設置して、ボデーに当たる光の照度をルーフ部分やサイド面などの場所ごとに測定して、データを収集します。
            <br>照度シミュレーションを行うことで見える化し、ログを残して今後に活かす提案を行います。
          </p>
        </div>
      </section>
      <section class="caseDetail__note">
        <h3 class="caseDetail__note__ttl js-scroll">
          「照度シミュレーション」とは？
        </h3>
        <p class="caseDetail__note__txt">
          検査工程や車両の寸法、必要な照明情報から導入後の工程を事前に計算・資料化致します。
          <br>また、弊社では照明のプロフェッショナルが最適な設置寸法をコンサルティング提案し、検査工程の最適化に貢献します。
        </p>
        <p class="caseDetail__note__img">
          <img src="/genbashiko/img/case/case02/image03.jpg" alt="" />
        </p>
        <p class="caseDetail__note__link">
          <router-link :to="{ name: 'ContactSimulation'}">
            照度シミュレーションお問い合わせ
          </router-link>
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  head: {
  title: {
    inner: 'Case02'
  },
  meta: [
    { property: 'og:title', content: 'Case02｜GENBA SHIKO' },
    { name: 'description', content: '照明のプロが不在で課題が把握されず、最適な照明や光環境が構築できないなら、コンサルティングの実施で適切な光環境を構築し生産効率向上に結びつけます。' },
    { property: 'og:description', content: '照明のプロが不在で課題が把握されず、最適な照明や光環境が構築できないなら、コンサルティングの実施で適切な光環境を構築し生産効率向上に結びつけます。' },
    { property: 'og:url', content: 'https://luci.co.jp/genbashiko/case/case02' },
    { property: 'og:type', content: 'article' }
    ]
  }
};
</script>
